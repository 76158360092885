import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet que creix en grups més o manco nombrosos. El capell és de color negre en forma de cadira de muntar, constituït per 3 o 4 lòbuls i pot mesurar fins 3 cm d’amplada. El peu és cilíndric, més gruixat a la base, llis i d’un color blanquinós. Les espores són blanques en massa, el·líptiques, llises, de 18-22 x 10-13 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      